/* eslint-disable import/no-extraneous-dependencies */
import { EBSSnapshot } from 'blues-corejs/dist'
import { restoreLastCleanSnapshotsScript } from './restore-last-clean-snapshots-script'
import { CLEAN_INFO, INFECTED_INFO } from './constants'

export const computeLastCleanRpRestoreCode = (
  ebsSnapshots: Array<EBSSnapshot>
): string => {
  const snapshotAwsIds = ebsSnapshots
    .map((snapshot) => `"${snapshot.awsId}"`)
    .join(', ')
  return restoreLastCleanSnapshotsScript(snapshotAwsIds)
}

export const computeLastInfectedRpRestoreCode = (): string => {
  return 'restore-last-infected-rp'
}

export const computeRestoreLastSnapshotsModalData = (
  ebsSnapshots: Array<EBSSnapshot>,
  isInfected?: boolean
) => {
  const modalTitle = isInfected ? INFECTED_INFO.title : CLEAN_INFO.title
  const modalDescription = isInfected
    ? INFECTED_INFO.description
    : CLEAN_INFO.description
  const restoreCode = isInfected
    ? computeLastInfectedRpRestoreCode()
    : computeLastCleanRpRestoreCode(ebsSnapshots)

  return {
    modalTitle,
    modalDescription,
    restoreCode,
  }
}
