export const CLEAN_INFO = {
  title: 'Restore last clean recovery point',
  description:
    'To restore to the last clean state of data, run the following script in the AWS Cloud Shell:',
}

export const INFECTED_INFO = {
  title: 'Restore last infected recovery point',
  description:
    'To restore to the last infected state of data, run the following script in the AWS Cloud Shell:',
}
