/* eslint-disable import/no-extraneous-dependencies */
import Dialog from '@mui/material/Dialog'
import RestoreLastSnapshotsModalTitle from './restore-last-snapshots-modal-title'
import { EBSSnapshot } from 'blues-corejs/dist'
import RestoreLastSnapshotsCodeSnippet from '@features/asset-page/modals/restore-last-snapshots-modal/restore-last-snapshots-code-snippet'
import { computeRestoreLastSnapshotsModalData } from '@features/asset-page/modals/restore-last-snapshots-modal/utils'
import { ModalContentWrapper, modalStyles } from './styles'

interface Props {
  isDialogOpen: boolean
  onModalClose: () => void
  isInfected?: boolean
  ebsSnapshots: Array<EBSSnapshot>
}

function RestoreLastSnapshotsModal({
  onModalClose,
  isDialogOpen,
  isInfected,
  ebsSnapshots,
}: Props) {
  const { modalTitle, modalDescription, restoreCode } =
    computeRestoreLastSnapshotsModalData(ebsSnapshots, isInfected)

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onModalClose}
      PaperProps={{
        sx: modalStyles,
      }}
    >
      <ModalContentWrapper>
        <RestoreLastSnapshotsModalTitle
          title={modalTitle}
          description={modalDescription}
          onModalClose={onModalClose}
        />
        <RestoreLastSnapshotsCodeSnippet restoreCode={restoreCode} />
      </ModalContentWrapper>
    </Dialog>
  )
}

export default RestoreLastSnapshotsModal
