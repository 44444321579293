import PreloaderConstants from '@lib/constants/preloader.constant'
import ArrHelper from '@lib/helpers/arr.helper'

export function getPreloader(key: PreloaderConstants) {
  return (state: any): boolean => state.preloaderState.preloaders.includes(key)
}

export function getPreloaderAny(keys: Array<PreloaderConstants>) {
  return (state: any): boolean =>
    ArrHelper.intersection(keys, state.preloaderState.preloaders).length > 0
}
