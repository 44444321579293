import { useEffect, useRef, useState } from 'react'

export const ONE_SECOND_IN_MS = 1000
export const INIT_COUNTDOWN = 15

export function useCountdown(
  initialSeconds: number,
  onComplete: () => void
): number {
  const [timeLeft, setTimeLeft] = useState(initialSeconds)
  const intervalIdRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    const startTime = Date.now()
    const endTime = startTime + initialSeconds * ONE_SECOND_IN_MS

    intervalIdRef.current = setInterval(() => {
      const now = Date.now()
      const secondsLeft = Math.round((endTime - now) / ONE_SECOND_IN_MS)

      if (secondsLeft <= 0) {
        clearInterval(intervalIdRef.current as NodeJS.Timeout)
        setTimeLeft(0)
        onComplete()
      } else {
        setTimeLeft(secondsLeft)
      }
    }, ONE_SECOND_IN_MS)

    return () => {
      if (intervalIdRef.current !== undefined) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = undefined
      }
    }
  }, [initialSeconds])

  return timeLeft
}
