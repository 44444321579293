import { useEffect, useRef } from 'react'

// usePrevious that helps you keep track of the previous value of a variable between renders.
// If we intend to use it throughout our project, we can move it to the root
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
