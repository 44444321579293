import Button from '@mui/material/Button'
import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import SystemHelper from '@lib/helpers/system.helper'

interface Props {
  text: string
}

const TIMEOUT = 1000

function CopyToClipboardButton({ text }: Props) {
  const [isCopied, setIsCopied] = useState(false)
  const icon = isCopied ? (
    <CheckIcon fontSize="inherit" />
  ) : (
    <FileCopyOutlinedIcon fontSize="inherit" />
  )

  const handleOnCopy = async () => {
    await SystemHelper.copyToClipboard(text)

    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, TIMEOUT)
  }

  return (
    <Button variant="el-sizeXS" startIcon={icon} onClick={handleOnCopy}>
      Copy
    </Button>
  )
}

export default CopyToClipboardButton
