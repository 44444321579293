/* eslint-disable import/no-extraneous-dependencies */
import {
  EBS,
  EC2,
  EFS,
  GenericHost,
  S3Bucket,
  Asset,
  OvaServer,
  EBSSnapshot,
} from 'blues-corejs/dist'
import React, { useEffect, useState } from 'react'
import { pechkinClient } from '@lib/clients/pechkin'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import { ListBackupsForAssetClient } from '@lib/clients'
import router from 'next/router'
import { PageHelper, StrHelper, SystemHelper } from '@lib/helpers'
import pagePathConstant from '@lib/constants/page-path.constant'
import { ASSET_ID_QUERY_KEY } from '@lib/constants'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { ScheduleCcJobsUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledCcJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import { ScheduledCcJobsClient } from '@features/scheduled-jobs-monitoring/clients'
import ToastHelper from '@lib/helpers/toast.helper'
import { RestoreLastSnapshotsModal } from '@features/asset-page/modals/restore-last-snapshots-modal'
import { useToggle } from '@lib/hooks'

const scheduleCcJobsUseCase = new ScheduleCcJobsUseCase(
  new ScheduledCcJobsRepository(createLogger()),
  new ScheduledCcJobsClient(),
  ToastHelper
)

const backupsClient = new ListBackupsForAssetClient()

type Props = {
  asset: Asset
}

type Option = {
  label: string
  onClick: () => void | Promise<void>
}

interface ActionsMenuProps {
  options: Array<Option>
}

const StyledActionButton = styled(Button)({
  padding: '4px 12px !important',
  minWidth: '100px !important',
  height: '33px !important',
})

export function ActionsMenu({ options }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <StyledActionButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={
          <ArrowForward
            sx={{
              fontSize: '15px',
            }}
          />
        }
      >
        <Typography
          variant="caption"
          fontWeight="600"
          lineHeight="21px"
          fontSize="14px"
        >
          Action
        </Typography>
      </StyledActionButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            onClick={() => {
              option.onClick()
              handleClose()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

function getRestoreInstanceLink(asset: EC2, rpId: string) {
  return `${PageHelper.buildUrl(
    pagePathConstant.RESTORE_INSTANCE,
    rpId
  )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(asset.id)}`
}

function getRestoreVolumeLink(asset: EBS, rpId: string) {
  return `${PageHelper.buildUrl(
    pagePathConstant.RESTORE_VOLUME,
    rpId
  )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(asset.id)}`
}

function getRestoreLink(asset: Asset, rpId: string) {
  if (asset instanceof EC2) {
    return getRestoreInstanceLink(asset, rpId)
  }

  if (asset instanceof EBS) {
    return getRestoreVolumeLink(asset, rpId)
  }

  SystemHelper.sendSentryIfProd(
    `Asset type not supported for restore: ${asset.id}`
  )
  throw new Error(`Asset type not supported for restore: ${asset.id}`)
}

async function scanAsset(asset: Asset) {
  if (asset instanceof EC2 || asset instanceof EBS) {
    const jobIds = await pechkinClient.scheduleAssetScans([asset])
    await scheduleCcJobsUseCase.execute(jobIds)
  } else {
    sentryReThrowCatchHandler(
      '[AssetHeaderAction] Asset type not supported for scanning'
    )
  }
}

function useGetLastCleanBackupByAssetId({
  asset,
  lastCleanBackupMap,
}: {
  asset: Asset
  lastCleanBackupMap: Map<string, ElastioRecoveryPoint>
}) {
  const [lastCleanBackup, setLastCleanBackup] = React.useState<
    ElastioRecoveryPoint | undefined
  >()

  React.useEffect(() => {
    async function getLastCleanBackup() {
      try {
        if (lastCleanBackupMap.has(asset.id)) {
          setLastCleanBackup(lastCleanBackupMap.get(asset.id))
          return
        }

        const response = await backupsClient.lastCleanBackupForAsset(asset.id)

        if (!response.elastioRps) {
          return
        }

        setLastCleanBackup(response.elastioRps)
        lastCleanBackupMap.set(asset.id, response.elastioRps)
      } catch (error) {
        console.error('Error while fetching last clean backup:', error)
      }
    }

    getLastCleanBackup()
  }, [])

  return lastCleanBackup
}

function handleRestoreToLastCleanBackup(
  asset: Asset,
  lastCleanBackup: ElastioRecoveryPoint
) {
  router.push(getRestoreLink(asset, lastCleanBackup.id))
}

interface ComputeActionsProps {
  asset: Asset
  lastCleanBackup?: ElastioRecoveryPoint
  lastCleanSnapshots: Array<EBSSnapshot>
  handleRestoreLastCleanSnapshot: () => void
}

function computeEc2OrEBSActions({
  asset,
  lastCleanBackup,
  lastCleanSnapshots,
  handleRestoreLastCleanSnapshot,
}: ComputeActionsProps) {
  const actions: Array<Option> = [
    {
      label: 'Scan',
      onClick: () => scanAsset(asset),
    },
  ]

  if (lastCleanBackup) {
    actions.push({
      label: 'Restore to last known clean recovery point',
      onClick: () => {
        handleRestoreToLastCleanBackup(asset, lastCleanBackup)
      },
    })
  }

  if (
    !lastCleanBackup &&
    asset instanceof EC2 &&
    lastCleanSnapshots.length > 0
  ) {
    actions.push({
      label: 'Restore last clean recovery point',
      onClick: handleRestoreLastCleanSnapshot,
    })
  }
  return actions
}

function computeActions(props: ComputeActionsProps) {
  const { asset } = props
  if (asset instanceof EC2 || asset instanceof EBS) {
    return computeEc2OrEBSActions(props)
  } else {
    return []
  }
}

function useGetLastCleanSnapshotsByEbsIds({
  asset,
  lastCleanBackup,
}: {
  asset: Asset
  lastCleanBackup?: ElastioRecoveryPoint
}) {
  const ebsIds = asset instanceof EC2 ? asset.ebsIds : []
  const [lastCleanSnapshots, setLastCleanSnapshots] = useState<
    Array<EBSSnapshot>
  >([])

  const { isActive, activate, deactivate } = useToggle()

  useEffect(() => {
    async function getLastCleanEbsSnapshots() {
      if (ebsIds.length === 0 || lastCleanBackup || !(asset instanceof EC2)) {
        return
      }
      try {
        await Promise.all(
          ebsIds.map((ebsId) => backupsClient.lastCleanBackupForAsset(ebsId))
        ).then((backups) => {
          const snapshots = backups.reduce(
            (acc: Array<EBSSnapshot>, backup) => {
              if (backup.ebsSnapshots) {
                return [...acc, backup.ebsSnapshots]
              }
              return acc
            },
            []
          )

          setLastCleanSnapshots(snapshots)
        })
      } catch (error) {
        console.error(
          'Error while fetching last clean snapshots for ec2:',
          error
        )
      }
    }

    getLastCleanEbsSnapshots()
  }, [lastCleanBackup])

  return {
    lastCleanSnapshots,
    showLastCleanSnapshotsModal: isActive,
    handleOpenLastCleanSnapshotModal: activate,
    handleCloseLastCleanSnapshotModal: deactivate,
  }
}

function AssetHeaderAction({ asset }: Props) {
  const lastCleanBackupMap = new Map<string, ElastioRecoveryPoint>()

  const lastCleanBackup = useGetLastCleanBackupByAssetId({
    asset,
    lastCleanBackupMap,
  })
  const {
    lastCleanSnapshots,
    showLastCleanSnapshotsModal,
    handleOpenLastCleanSnapshotModal,
    handleCloseLastCleanSnapshotModal,
  } = useGetLastCleanSnapshotsByEbsIds({
    asset,
    lastCleanBackup,
  })

  if (
    asset instanceof GenericHost ||
    asset instanceof EFS ||
    asset instanceof S3Bucket ||
    asset instanceof OvaServer
  ) {
    return null
  } else {
    return (
      <>
        <ActionsMenu
          options={computeActions({
            asset,
            lastCleanBackup,
            lastCleanSnapshots,
            handleRestoreLastCleanSnapshot: handleOpenLastCleanSnapshotModal,
          })}
        />
        {showLastCleanSnapshotsModal && (
          <RestoreLastSnapshotsModal
            ebsSnapshots={lastCleanSnapshots}
            onModalClose={handleCloseLastCleanSnapshotModal}
            isDialogOpen={showLastCleanSnapshotsModal}
          />
        )}
      </>
    )
  }
}

export default AssetHeaderAction
