import React, { ReactNode } from 'react'
import ConfigureView from '@components-composite/configure-view/ConfigureView'
import FullStory from '@components-complex/layouts/sections/full-story/FullStory'
import { TopNavigationBar } from '@features/TopNavigationBar'
import Stack from '@mui/material/Stack'
import { useInitOnboarding } from '@lib/hooks/useInitOnboarding'
import { DynamicBreadcrumbs } from '@features/DynamicBreadcrumbs'
import styles from './MainTopBarLayout.module.scss'
import { LayoutProvider } from '@features/contexts'
import WarningMessage from '@components-simple/messages/warning-message/WarningMessage'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useAppStoreActions, KEYS } from '@lib/zustand/use-page-store'
import { useFetchLiveAssets } from '@features/live-assets'
import { useFetchUserData } from '@lib/hooks/api-hooks'
import { LoadingScreen } from '@components-simple/loading-screen'
import ZeWebMessenger from '@components-complex/layouts/sections/ze-web-messenger/ze-web-messenger'
import AssetHeaderAction from '@features/asset-page/more-details/more-details-components/asset-header-action'

interface Props {
  children: ReactNode
  title: string
}

const maintenanceMessage =
  'Elastio Portal is being upgraded at the moment. Some information might not be displayed correctly in the Tenant as a result. <br/>Elastio protection and retention policies and all related workloads will run as usual during this time, however, the jobs and their results might not show up in Elastio Tenant right away.'

function MainTopBarLayout({ children, title }: Props) {
  const { maintenanceMode } = useCustomFlags()
  const { isRedStackExists } = useInitOnboarding()
  const { isProfileFetched, isConfigFetched } = useFetchUserData()

  const { getValue } = useAppStoreActions()

  const currentAsset = getValue(KEYS.currentAsset)
  useFetchLiveAssets()

  if (!isProfileFetched || !isConfigFetched) {
    return <LoadingScreen />
  }

  return (
    <LayoutProvider>
      <div data-testid="main-top-bar-layout" className={styles.layout}>
        <TopNavigationBar
          title={title}
          showOnlyOnboarding={!isRedStackExists}
        />
        {maintenanceMode && (
          <WarningMessage
            message={maintenanceMessage}
            className={'maintenanceMessage'}
          />
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          px={3}
        >
          <DynamicBreadcrumbs />
          {currentAsset && <AssetHeaderAction asset={currentAsset} />}
        </Stack>

        {children}
        <div id="base-portal-root" />

        <ZeWebMessenger />
        <FullStory />
        <ConfigureView />
      </div>
    </LayoutProvider>
  )
}

export default MainTopBarLayout
