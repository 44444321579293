import { useCallback, useState } from 'react'
/**
 * Controls and state for a toggleable UI component.
 */
type ToggleActionsState = {
  /** Indicates whether the component is currently active. */
  isActive: boolean
  /** Toggles the active state between true and false. */
  toggle: () => void
  /** Sets the active state to true. */
  activate: () => void
  /** Sets the active state to false. */
  deactivate: () => void
}

function useToggle(initialState = false): ToggleActionsState {
  const [isActive, setIsActive] = useState<boolean>(initialState)

  const toggle = useCallback(() => {
    setIsActive((prevState) => !prevState)
  }, [])

  const activate = useCallback(() => {
    setIsActive(true)
  }, [])

  const deactivate = useCallback(() => {
    setIsActive(false)
  }, [])

  return {
    isActive,
    toggle,
    activate,
    deactivate,
  }
}

export { useToggle }
