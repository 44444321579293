import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

interface Props {
  title: string
  description: string
  onModalClose: () => void
}

function RestoreLastSnapshotsModalTitle({
  title,
  description,
  onModalClose,
}: Props) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
      >
        <Typography
          variant="h5"
          fontWeight="700"
          marginBottom="0"
          fontSize="22px"
        >
          {title}
        </Typography>
        <IconButton onClick={onModalClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="body1" margin="16px 0">
        {description}
      </Typography>
    </>
  )
}

export default RestoreLastSnapshotsModalTitle
