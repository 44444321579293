import { useCallback, useState } from 'react'

const DEFAULT_TAB_INDEX = 0

interface TabState {
  onTabChange: (targetTabIndex: number) => void
  activeTab: number
}

function useTab(initialTabIndex = DEFAULT_TAB_INDEX): TabState {
  const [activeTab, setActiveTab] = useState<number>(initialTabIndex)

  const onTabChange = useCallback((targetTabIndex: number) => {
    setActiveTab(targetTabIndex)
  }, [])
  return {
    onTabChange,
    activeTab,
  }
}

export { useTab }
