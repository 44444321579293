/* eslint-disable import/no-extraneous-dependencies */
import { useCallback } from 'react'
import { create } from 'zustand'
import { Asset } from 'blues-corejs/dist'

interface AppState {
  pageModel?: object
  hasThreats?: boolean
  currentAsset?: Asset
}

interface StoreState {
  values: AppState
  setValue: <K extends keyof AppState>(key: K, value: AppState[K]) => void
  resetValue: <K extends keyof AppState>(key: K) => void
  getValue: <K extends keyof AppState>(key: K) => AppState[K]
}

const useStore = create<StoreState>((set, get) => ({
  values: {},
  setValue: (key, value) =>
    set((state) => {
      if (state.values[key] === value) {
        return state
      }
      return {
        values: {
          ...state.values,
          [key]: value,
        },
      }
    }),
  resetValue: (key) =>
    set((state) => {
      if (!(key in state.values) || state.values[key] === undefined) {
        return state
      }
      const newState = { ...state.values }
      delete newState[key]
      return { values: newState }
    }),
  getValue: (key) => get().values[key],
}))

export const useAppStoreActions = () => {
  const store = useStore()

  const setValue = useCallback((key, value) => store.setValue(key, value), [])
  const resetValue = useCallback((key) => store.resetValue(key), [])
  const getValue = useCallback((key) => store.getValue(key), [])

  return {
    setValue,
    resetValue,
    getValue,
  }
}

export const KEYS = {
  hasThreats: 'hasThreats',
  currentAsset: 'currentAsset',
} as const
