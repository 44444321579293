import { styled } from '@mui/material/styles'
import DialogContent from '@mui/material/DialogContent'
import { CSSProperties } from 'react'

export const ModalContentWrapper = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
})

export const modalStyles = {
  width: '45%!important',
  minWidth: '780px!important',
}

export const codeSnippetStyles: CSSProperties = {
  margin: '0 0 16px 0',
  padding: '12px',
  overflowY: 'auto',
  fontSize: '14px',
  flex: 1,
}
