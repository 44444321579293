import { CopyToClipboardButton } from '@features/common/clipboard-button'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import Box from '@mui/material/Box'
import { codeSnippetStyles } from './styles'

interface Props {
  restoreCode: string
}

function RestoreLastSnapshotsCodeSnippet({ restoreCode }: Props) {
  return (
    <>
      <SyntaxHighlighter
        language="bash"
        style={docco}
        customStyle={codeSnippetStyles}
      >
        {restoreCode}
      </SyntaxHighlighter>
      <Box>
        <CopyToClipboardButton text={restoreCode} />
      </Box>
    </>
  )
}

export default RestoreLastSnapshotsCodeSnippet
