import { useCallback, useState } from 'react'

type PageToken = string | undefined

interface UsePaginationOutput {
  pageToken: PageToken
  updatePageTokenAndFlagFetched: (newToken: PageToken) => void
  isNextPageAvailable: () => boolean
  resetPagination: () => void
}

export function usePagination(): UsePaginationOutput {
  const [pageToken, setPageToken] = useState<PageToken>(undefined)
  const [hasFetchedFirstPage, setHasFetchedFirstPage] = useState<boolean>(false)

  const updatePageTokenAndFlagFetched = useCallback((newToken: PageToken) => {
    setPageToken(newToken)
    setHasFetchedFirstPage(true)
  }, [])

  /**
   * Determines if a page token is available for fetching the next page.
   * @returns {boolean} - True if a page token is available or if the first page has not been fetched yet.
   */
  const isNextPageAvailable = useCallback(() => {
    return !hasFetchedFirstPage || (pageToken !== undefined && pageToken !== '')
  }, [hasFetchedFirstPage, pageToken])

  /**
   * Resets the state to its IS.
   */
  const resetPagination = useCallback(() => {
    setPageToken(undefined)
    setHasFetchedFirstPage(false)
  }, [])
  return {
    pageToken,
    updatePageTokenAndFlagFetched,
    isNextPageAvailable,
    resetPagination,
  }
}
